<template>
  <div id="wrapper">
	<div class="query-content">
		<div class="content-title">
			<p style="color:#000">仙福君防伪查询</p>
		</div>

		<div class="content-315">
			<!-- <img class="img-logo" src="@assets/xlanji.png"  > -->
			<img src="@assets/xfujun.png"  >
		</div>

    <!-- <div style="padding: 10px;">
      <p style="text-indent: 2em; font-size:20px;color: #ffffff;"><b>莹秀如兰，集采若璠。秀兰集与您共筑美肤之路。</b></p>
    </div> -->

		<div class="content-fangwei">
			<img class="content-fangwei-img" src="@assets/images/code.png">
		</div>
    <div class="process">
			<p>01刮开涂层>02输入防伪码>03点击查询>04显示防伪查询结果</p>
		</div>

		<div class="content-fangweima">
			<p><input type="text" name="fwm"  v-model="code" placeholder="请输入防伪密码"><button @click="getCodeDetail">查询</button></p>
		</div>

		<div class="content-desc">
			<p>温馨提示:</p>
			<p style="text-indent: 2em; font-size:15px;">①防伪码如果经过多次查询，系统会自动提示查询总次数，请留意！</p>
			<p style="text-indent: 2em; font-size:15px;">②微信扫码请点击右上角菜单“...”，弹窗上方显示“此网页由cx.cc12315.cn提供”。请注意其中12315为纯数字，无字母。</p>
      <p style="color:red"><b>网址不同，必定假货。网址相同则为正品。</b></p>
			<p style="text-indent: 2em; font-size:15px;">③如有疑问可联系天猫店铺名“仙福君旗舰店”咨询。</p>
		</div>
    <div class="content-desc">
			<p>查询方式:</p>
      <div class="content-desc-type">
          <div class="query">
            <img class="query-img" src="@assets/images/saoma.png">
            <div>扫码查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/chaxun.png">
              <div>网站查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/phone.png">
              <div>电话查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/gongzhonghao.png">
              <div>公众号查询</div>
          </div>
      </div>

		</div>
		<div class="end" style="">
			<ul>
				<!-- <li>备案号&nbsp;:&nbsp;&nbsp;<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备18108206号</a></li> -->
				<li>COPYRIGHT © 仙福君 </li>
				<li style="font-size: 14px;">智源信息提供技术支持</li>
			</ul>
		</div>
	</div>
</div>

</template>

<script>
import { getCode } from '@api/public'
export default {
  name: 'Xlanji',
  props: {},
  data: function () {
    const { code = '' } = this.$route.query
    return {
      code_status: 0, // 二维码查询状态
      code: code,
      rel_code_batch:['1041']
    }
  },
  mounted: function () {
    if(this.code != ''){
        this.getCodeDetail()
    }
  },
  methods: {
    getCodeDetail () {
      var code = this.code
      // console.log(code.substring(0,6))
      if(code == ''){
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          layer.msg('防伪码不能为空')
        })
        return false;
      }
      if (!(this.rel_code_batch.includes(code.substring(0,4)))){
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          layer.msg('请输入正确品牌的防伪密码')
        })
        return false;
      }
      getCode(code).then(res => {
        var content = '';
        if(res.status == 200){
            var company_name  = res.data.company_name;
            var brand_name    = res.data.brand_name == "" ? "正牌" : res.data.brand_name;
            var queries_number  = res.data.queries_number;
            var query_time      = res.data.query_time;
            var content = '<div class="tankuang tankuang-g"><div class="content-bg content-bg-gzhi"><p class="tankuang-title s">恭喜您</p><p class="tankuang-content">您好，您所查询的产品是仙福君正品。药食同源，寓医于食，很荣幸帮助您照顾您的健康。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;微信扫码请点击右上角的三个点“...”，弹窗的上方会显示“此网页由cx.cc12315.cn提供”。请注意其中12315为纯数字，无字母。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网址不同，必定假货。网址相同则为正品。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;如有疑问可联系天猫店铺名“仙福君旗舰店”咨询。<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;这是第'+queries_number+'次查询！最近一次查询时间:'+query_time+'</p></div><button class="tankuang-button-zhi" onclick="closeCode()">关闭</button></div> ';
        }else{
          var content   = '<div class="tankuang tankuang-b"><div class="content-bg content-bg-b"><p class="tankuang-title o">很抱歉！</p><p class="tankuang-content">很抱歉,您所查询的防伪码'+code+'无效,请重新输入16位有效的防伪码!有疑问请联系品牌商。</p></div><button class="tankuang-button" style="background: #66c527;" onclick="closeCode()">关闭</button></div>';
        }
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          const querry_l = layer.open({
						title:"查询结果",
						type:'1',
            move:false,
            shadeClose: true,
						content:content
					});
          // layer.full(querry_l)
        })
      })
    }
  }
}
window.closeCode = function(){
      window.layui.use('layer', function () {
        const layer = window.layui.layer
        layer.close(layer.index);
      }) 
    }
</script>
<style scoped>
  #wrapper{
    font-family: "微软雅黑" !important;
    color: #000000;
    }
  .query-content{
    /* background:url(../images/bg.png)no-repeat center center; */
    /* background-image: linear-gradient(180deg,#27f550, #b6dec7 0%, #72ab8b 100%); */
    background-image: linear-gradient(130deg,#82f699 0%, #72ab8b 100%);
    /* background: #b6dec7; */
    /* background-size: 100% 100%; */
    padding-bottom: 5px;
  }
  .content-315 img{
    height: 100%;
    width: 100%;
  }
/* 不能被引用，为何？只能写在common里面 */

  .query-img{
  width: 35px;
  height: 35px;
}
</style>
