<template>
  <div id="wrapper">
	<div class="query-content">
		<div class="content-title">
			<p>商品数码防伪中心</p>
		</div>

		<div class="content-315">
			<img src="@assets/images/315_3.png">
		</div>

		<div class="content-fangwei">
			<img class="content-fangwei-img" src="@assets/images/code.png">
		</div>
    <div class="process">
			<p>01刮开涂层>02输入防伪码>03点击查询>04显示防伪查询结果</p>
		</div>

		<div class="content-fangweima">
			<p><input type="text" name="fwm" disabled=true v-model="code" placeholder="请扫描防伪二维码"><button @click="getCodeDetail">查询</button></p>
		</div>

		<div class="content-desc">
			<p>温馨提示:</p>
			<p style="text-indent: 2em; font-size:13px;">防伪码如果经过多次查询，系统会自动提示查询总次数，请留意！</p>
		</div>
    <div class="content-desc">
			<p>查询方式:</p>
      <div class="content-desc-type">
          <div class="query">
            <img class="query-img" src="@assets/images/saoma.png">
            <div>扫码查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/chaxun.png">
              <div>网站查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/phone.png">
              <div>电话查询</div>
          </div>
          <div class="query">
              <img class="query-img" src="@assets/images/gongzhonghao.png">
              <div>公众号查询</div>
          </div>
      </div>

		</div>
		<div class="end" style="">
			<ul>
				<li>备案号&nbsp;:&nbsp;&nbsp;<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备18108206号</a></li>
				<li>版权信息 广州智源信息科技有限公司</li><li>
			</li></ul>
		</div>
	</div>
</div>

</template>

<script>
import { getCode } from '@api/public'
import z_img from '@/assets/images/a2201guyuangao.jpg'
export default {
  name: 'A2201',
  props: {},
  data: function () {
    const { code = '' } = this.$route.query
    return {
      code_status: 0, // 二维码查询状态
      code: code,
      rel_code_batch:['101911']
    }
  },
  mounted: function () {
    if(this.code != ''){
        this.getCodeDetail()
    }
  },
  methods: {
    getCodeDetail () {
      var code = this.code
      // console.log(code.substring(0,6))
      if(code == ''){
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          layer.msg('防伪码不能为空')
        })
        return false;
      }
      if (!(this.rel_code_batch.includes(code.substring(0,6)))){
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          layer.msg('请扫描正确品牌的防伪二维码')
        })
        return false;
      }
      getCode(code).then(res => {
        var content = '';
        if(res.status == 200){
            var company_name  = res.data.company_name;
            var brand_name    = res.data.brand_name == "" ? "正牌" : res.data.brand_name;
            var queries_number  = res.data.queries_number;
            var query_time      = res.data.query_time;
            // var content = '<div class="tankuang tankuang-g"><div class="content-bg content-bg-g"><p class="tankuang-title s">恭喜您</p><p class="tankuang-content">您好,您所查询的产品属于'+company_name+'的'+brand_name+'产品,请放心使用,感谢您的购买,这是第'+queries_number+'次查询！最近一次查询时间:'+query_time+'</p></div><button class="tankuang-button" onclick="closeCode()">关闭</button></div> ';
            var content = '<div class="tankuang tankuang-g"><div class=""><p class="tankuang-title">恭喜您</p><p class="tankuang-content">您好,您所查询的产品是正牌产品,请放心使用,感谢您的购买,这是第'+queries_number+'次查询！最近一次查询时间:'+query_time+'</p></div><div><img class="request-res-img" src='+z_img+'></div><button class="tankuang-button" onclick="closeCode()">关闭</button></div> ';
        }else{
          var content   = '<div class="tankuang tankuang-b"><div class="content-bg content-bg-b"><p class="tankuang-title o">很抱歉！</p><p class="tankuang-content">很抱歉,您所查询的防伪码'+code+'无效,请重新输入16位有效的防伪码!有疑问请联系品牌商。</p></div><button class="tankuang-button" style="background: #66c527;" onclick="closeCode()">关闭</button></div>';
        }
        window.layui.use('layer', function () {
          const layer = window.layui.layer
          const querry_l = layer.open({
						title:"查询结果",
						type:'1',
            move:false,
            shadeClose: true,
						content:content
					});
          // layer.full(querry_l)
        })
      })
    }
  }
}
window.closeCode = function(){
      window.layui.use('layer', function () {
        const layer = window.layui.layer
        layer.close(layer.index);
      }) 
    }
</script>

